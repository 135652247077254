import React, { useState } from 'react';
import axios from 'axios';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import logo from './images/SHAFF.png';
import { useNavigate } from 'react-router-dom';

const Login = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_HOST}/api/users/login`, {
        username,
        password,
      });

      // Handle login success (store token, redirect, etc.)
      console.log(response);
      const { token, user } = response.data; // Assuming the response contains a 'token' field
      const id_user = user.id;
      const id_karyawan = user.employeeId;
      const role = user.role;

        // Store the token in local storage
        localStorage.setItem('token', token);
        localStorage.setItem('id_user', id_user);
        localStorage.setItem('id_karyawan', id_karyawan);
        if (id_user != 1) {
          navigate('/user');
        }else{
          navigate('/admin');
        }
      
      //alert('SUKSES LOGIN');
    } catch (error) {
      setError('Invalid username or password');
    } finally {
      setLoading(false);
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  }

  return (
    <div className='row'>
        <div className='col-7'>
          <img className='w-100 vh-100' src='https://img.freepik.com/premium-photo/hris-recruiting-online-concept-with-hr-manager-hands-using-digital-tablet-virtual-wall-screen-background-with-resume_670147-1127.jpg' alt='logo'></img>
        </div>
        <div className='col-5'>
        <div className="container vh-100 w-100 d-flex justify-content-center align-items-center">
          <div className="card w-75 p-5">
            {/* <h2 className="text-center">Login Shaff</h2> */}
            <img src={logo} alt='logo shaff' className='mb-5'></img>
            {error && <div className="alert alert-danger">{error}</div>}
            <form onSubmit={handleLogin} style={{ fontWeight: 'bold' }} className='justify-content-start'>
              <div className="form-group">
                <label htmlFor="username">Username</label>
                <input
                  type="text"
                  className="form-control"
                  id="username"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                  placeholder="Enter your username"
                  required
                />
              </div>
              <div className="form-group position-relative">
                <label htmlFor="password">Password</label>
                <input
                  type={showPassword ? 'text' : 'password'}
                  className="form-control"
                  id="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  placeholder="Enter your password"
                  required
                />
                 <span
                  onClick={togglePasswordVisibility}
                  className="position-absolute"
                  style={{ top: '38px', right: '10px', cursor: 'pointer' }}
                >
                  {showPassword ? <FaEyeSlash /> : <FaEye />}
                </span>
              </div>
              <button type="submit" className="btn btn-primary btn-block" disabled={loading}>
                {loading ? (
                  <span className='spinner-border spinner-border-sm'
                    role='status'
                    aria-hidden='true'></span>
                ) : ('Login')}
              </button>
            </form>
          </div>
        </div>
        </div>
    </div>
   
  );
};

export default Login;
