import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Container, Row, Col, Button, Navbar, Nav, Tab, Tabs, Table } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import './Profile.css'; 
import logo from './images/SHAFF.png';

const Profile = () => {
  const [profile, setProfile] = useState(null);
  const [penghasilan, setPenghasilan] = useState([]);
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const [key, setKey] = useState('profil');

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const token = localStorage.getItem('token');
        const id_user = localStorage.getItem('id_user');
        const id_karyawan = localStorage.getItem('id_karyawan');

        const response = await axios.get(`${process.env.REACT_APP_API_HOST}/api/employees/${id_user}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        setProfile(response.data);

        const penghasilanResponse = await axios.get(`${process.env.REACT_APP_API_HOST}/api/penghasilan/karyawan/${id_karyawan}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setPenghasilan(penghasilanResponse.data);
      } catch (err) {
        setError('Failed to fetch profile information');
        navigate('/login')
      }
    };

    fetchProfile();
  }, []);

  const formatRupiah = (amount) => {
    return `Rp ${Number(amount).toLocaleString('id-ID')}`;
  };

  const handlePengajuan = async (id_penghasilan) => {
    const token = localStorage.getItem('token');
    const id_user = localStorage.getItem('id_user');
    const id_karyawan = localStorage.getItem('id_karyawan');

    try {
      // Confirmation Alert
      const result = await Swal.fire({
        title: 'Apakah anda yakin?',
        text: 'Anda ingin mengajukan pencairan ini?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Ya, ajukan!'
      });

      if (result.isConfirmed) {
        await axios.put(`${process.env.REACT_APP_API_HOST}/api/penghasilan/request-cair/${id_penghasilan}`, 
          {
            id_karyawan: id_karyawan // Ini adalah data yang dikirimkan dalam request body
          }, 
          {
            headers: {
              Authorization: `Bearer ${token}`,
            }
          }
        );        

        // Success Alert
        Swal.fire(
          'Berhasil!',
          'Pengajuan pencairan berhasil dilakukan.',
          'success'
        );

        // Refresh the penghasilan data
        const penghasilanResponse = await axios.get(`${process.env.REACT_APP_API_HOST}/api/penghasilan/karyawan/${id_karyawan}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setPenghasilan(penghasilanResponse.data);
      }
    } catch (err) {
      Swal.fire(
        'Gagal!',
        'Pengajuan pencairan gagal dilakukan.',
        'error'
      );
    }
  };

  if (error) {
    return <div className="alert alert-danger">{error}</div>;
  }

  if (!profile) {
    return <div>Loading...</div>;
  }

  return (
    <div className="profile-page">
      <Navbar className='navbar'>
        <Container>
          <Navbar.Brand href="#home" className='pt-2'> <img src={logo} alt='logo shaff' width={250}></img></Navbar.Brand>
          <Nav className="ml-auto">
            <Navbar.Text className='text-navbar'>
              Hi, {profile.name}
            </Navbar.Text>
          </Nav>
        </Container>
      </Navbar>

      <Container className="profile-section text-center" fluid>
        <Row className="justify-content-center">
          <Col md={5} className="profile-card">
            <img src={profile.photo} alt="Profile" className="profile-photo" />
            <h3>{profile.name}</h3>
            <p>{profile.position}</p>
            <Button variant="warning">Ganti foto profile</Button>
          </Col>
        </Row>
      </Container>

      <div className="tabs-section">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} className="text-center">
              <Tabs
                id="controlled-tab-example"
                activeKey={key}
                onSelect={(k) => setKey(k)}
                className="mb-3 justify-content-center"
              >
                <Tab eventKey="profil" title="Profil">
                  <div className="tab-content">
                    <Table className='table table-striped table-secondary'>
                      <tbody>
                        <tr>
                          <th>Nama</th>
                          <td>{profile.name}</td>
                        </tr>
                        <tr>
                          <th>Posisi</th>
                          <td>{profile.position}</td>
                        </tr>
                        <tr>
                          <th>Departemen</th>
                          <td>{profile.department}</td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                </Tab>
                <Tab eventKey="penghasilan" title="Penghasilan">
                  <div className="tab-content">
                    <Table className='table table-striped table-secondary'>
                      <thead>
                        <tr>
                          <th>ID</th>
                          <th>Penghasilan</th>
                          <th>Keterangan</th>
                          <th>Status</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {penghasilan.map((item) => (
                          <tr key={item.id}>
                            <td>{item.id}</td>
                            <td>{formatRupiah(item.penghasilan)}</td>
                            <td>{item.keterangan}</td>
                            <td>{item.status}</td>
                            <td>
                              <Button 
                                variant="primary" 
                                onClick={() => handlePengajuan(item.id)}
                                disabled={item.status !== 'belum dicairkan'}
                              >
                                Ajukan Pencairan
                              </Button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                </Tab>
                <Tab eventKey="jobdesc" title="Jobdesc">
                  <div className="tab-content">
                    <h5>Jobdesc</h5>
                    <p>Here is the content for Jobdesc tab...</p>
                  </div>
                </Tab>
              </Tabs>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default Profile;
