import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import DataTable from 'react-data-table-component';
import styled from 'styled-components';

const Admin = () => {
  const [employees, setEmployees] = useState([]);
  const [penghasilan, setPenghasilan] = useState([]);
  const [newEmployee, setNewEmployee] = useState({
    name: '',
    position: '',
    department: '',
    username: '',
    password: ''
  });
  const [newIncome, setNewIncome] = useState({
    id_karyawan: '',
    penghasilan: '',
    keterangan: '',
    status: 'belum dicairkan'
  });

  const navigate = useNavigate();

  useEffect(() => {
    fetchEmployees();
    fetchPenghasilan();
  }, []);

  const fetchEmployees = async () => {
    try {
      const token = localStorage.getItem('token');
      const id_user = localStorage.getItem('id_user');
      if (id_user != 1) {
        navigate('/login')
      }
      const response = await axios.get(`${process.env.REACT_APP_API_HOST}/api/employees/all`, {
        headers: { Authorization: `Bearer ${token}` }
      });
      setEmployees(response.data);
    } catch (error) {
      console.error('Error fetching employees:', error);
    }
  };

  const fetchPenghasilan = async () => {
    try {
      const token = localStorage.getItem('token');
      
      const response = await axios.get(`${process.env.REACT_APP_API_HOST}/api/penghasilan`, {
        headers: { Authorization: `Bearer ${token}` }
      });
      setPenghasilan(response.data);
    } catch (error) {
      console.error('Error fetching income records:', error);
    }
  };

  const handleEmployeeInputChange = (e) => {
    const { name, value } = e.target;
    setNewEmployee({ ...newEmployee, [name]: value });
  };

  const handleIncomeInputChange = (e) => {
    const { name, value } = e.target;
    setNewIncome({ ...newIncome, [name]: value });
  };

  const handleAddEmployee = async (e) => {
    e.preventDefault();
    try {
      const token = localStorage.getItem('token');
      const response = await axios.post(`${process.env.REACT_APP_API_HOST}/api/employees/add`, newEmployee, {
        headers: { Authorization: `Bearer ${token}` }
      });
      Swal.fire('Success', 'Employee added successfully!', 'success');
      fetchEmployees();
    } catch (error) {
      Swal.fire('Error', 'Failed to add employee.', 'error');
    }
  };

  const handleAddIncome = async (e) => {
    e.preventDefault();
    try {
      const token = localStorage.getItem('token');
      const response = await axios.post(`${process.env.REACT_APP_API_HOST}/api/penghasilan`, newIncome, {
        headers: { Authorization: `Bearer ${token}` }
      });
      Swal.fire('Success', 'Income added successfully!', 'success');
      fetchPenghasilan();
    } catch (error) {
      Swal.fire('Error', 'Failed to add income.', 'error');
    }
  };

  const handleUpdateIncomeStatus = async (id, status) => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.put(`${process.env.REACT_APP_API_HOST}/api/penghasilan/${id}`, { status }, {
        headers: { Authorization: `Bearer ${token}` }
      });
      Swal.fire('Success', 'Income status updated!', 'success');
      fetchPenghasilan();
    } catch (error) {
      Swal.fire('Error', 'Failed to update income status.', 'error');
    }
  };

  // Define columns for DataTables
  const employeeColumns = [
    { name: 'ID', selector: row => row.id, sortable: true },
    { name: 'Name', selector: row => row.name, sortable: true },
    { name: 'Position', selector: row => row.position, sortable: true },
    { name: 'Department', selector: row => row.department, sortable: true },
    { name: 'Photo', selector: row => row.photo, sortable: true },
  ];

  const incomeColumns = [
    { name: 'ID', selector: row => row.id, sortable: true },
    { name: 'Employee Name', selector: row => row.employee_name, sortable: true },
    { name: 'Income', selector: row => row.penghasilan, sortable: true },
    { name: 'Description', selector: row => row.keterangan, sortable: true },
    { name: 'Status', selector: row => row.status, sortable: true },
    {
      name: 'Actions',
      cell: (row) => (
        <Button
          variant="success"
          onClick={() => handleUpdateIncomeStatus(row.id, 'sudah dicairkan')}
          disabled={row.status === 'sudah dicairkan'}
        >
          Mark as Withdrawn
        </Button>
      ),
    },
  ];

  return (
    <Container>
      <Row>
        <Col md={6}>
          <h2>Add New Employee</h2>
          <Form onSubmit={handleAddEmployee}>
            <Form.Group>
              <Form.Label>Name</Form.Label>
              <Form.Control type="text" name="name" onChange={handleEmployeeInputChange} />
            </Form.Group>
            <Form.Group>
              <Form.Label>Position</Form.Label>
              <Form.Control type="text" name="position" onChange={handleEmployeeInputChange} />
            </Form.Group>
            <Form.Group>
              <Form.Label>Department</Form.Label>
              <Form.Control type="text" name="department" onChange={handleEmployeeInputChange} />
            </Form.Group>
            <Form.Group>
              <Form.Label>Username</Form.Label>
              <Form.Control type="text" name="username" onChange={handleEmployeeInputChange} />
            </Form.Group>
            <Form.Group>
              <Form.Label>Password</Form.Label>
              <Form.Control type="password" name="password" onChange={handleEmployeeInputChange} />
            </Form.Group>
            <Form.Group>
              <Form.Label>Photo</Form.Label>
              <Form.Control type="photo" name="photo" onChange={handleEmployeeInputChange} />
            </Form.Group>
            <Button type="submit" variant="primary">Add Employee</Button>
          </Form>
        </Col>

        <Col md={6}>
          <h2>Add New Income</h2>
          <Form onSubmit={handleAddIncome}>
            <Form.Group>
              <Form.Label>Employee</Form.Label>
              <Form.Control as="select" name="id_karyawan" onChange={handleIncomeInputChange}>
                <option>Select Employee</option>
                {employees.map(employee => (
                  <option key={employee.id} value={employee.id}>{employee.name}</option>
                ))}
              </Form.Control>
            </Form.Group>
            <Form.Group>
              <Form.Label>Income</Form.Label>
              <Form.Control type="number" name="penghasilan" onChange={handleIncomeInputChange} />
            </Form.Group>
            <Form.Group>
              <Form.Label>Description</Form.Label>
              <Form.Control type="text" name="keterangan" onChange={handleIncomeInputChange} />
            </Form.Group>
            <Button type="submit" variant="primary">Add Income</Button>
          </Form>
        </Col>
      </Row>

      <Row className="mt-5">
        <Col>
          <h2>All Employees</h2>
          <DataTable
            columns={employeeColumns}
            data={employees}
            pagination
            fixedHeader
            highlightOnHover
          />
        </Col>
      </Row>

      <Row className="mt-5">
        <Col>
          <h2>All Incomes</h2>
          <DataTable
            columns={incomeColumns}
            data={penghasilan}
            pagination
            fixedHeader
            highlightOnHover
          />
        </Col>
      </Row>
    </Container>
  );
};

export default Admin;
